import NMapboxPopup from "./n-mapbox-popup.js";

class ROUTING_PROFILES {
}

ROUTING_PROFILES.DRIVING_TRAFFIC = 'mapbox/driving-traffic'
ROUTING_PROFILES.DRIVING = 'mapbox/driving'
ROUTING_PROFILES.CYCLING = 'mapbox/cycling'
ROUTING_PROFILES.WALKING = 'mapbox/walking'

const ZOOM_LEVEL_DEFAULT = 5
const ZOOM_LEVEL_CLOSE = 11

class RADIUS {
}

RADIUS.SMALL = 20
RADIUS.MEDIUM = 30
RADIUS.LARGE = 40

class THRESHOLD {
}

THRESHOLD.FIRST = 10
THRESHOLD.SECOND = 50

class EVENTS {
}

EVENTS.ADD_LOCATION = 'on-add-location'
EVENTS.ON_SEARCH = 'on-search'
EVENTS.SELECT_LOCATION = 'on-select-location'
export default {
    props: {
        resizeMap: Boolean,
        placesData: Object,
        canPlace: Boolean,
        isDisabled: {
            default: false,
            type: Boolean,
        },
        center: Array
    },
    emits: Object.values(EVENTS),
    components: {
        'n-mapbox-popup': NMapboxPopup
    },
    watch: {

        placesData: {
            handler(newValue) {
                mapboxgl.accessToken = this.$root.MAPBOX_API;
                const hasUndefinedValue = this.center?.some(latLang => latLang === undefined)

                if (this.center?.length > 0 && !hasUndefinedValue) {
                    this.zoom = ZOOM_LEVEL_CLOSE
                    this.setupMap(this.center);
                    return
                }

                this.getUserLocation()
                    .then(position => {
                        const userCenter = [position.coords.longitude, position.coords.latitude];
                        this.setupMap(userCenter);

                    })
                    .catch(error => {
                        console.error('Error getting user location:', error);
                        //TODO
                        const swedenCenter = [18.643501, 60.128161];
                        this.setupMap(swedenCenter);
                    });
            },
            deep: true,
        },
    },
    data() {
        return {
            map: null,
            directions: null,
            selectedMarkers: [],
            routeData: {},
            zoom: ZOOM_LEVEL_DEFAULT,
            stops: [],
            popup: null,
            mapboxSearchField: null,
            searchMarker: null,

            clusterTextStyle: {
                'text-field': '{point_count_abbreviated}',
                'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                'text-size': 12
            },
            circleColor: [
                'step',
                ['get', 'point_count'],
                '#51bbd6',
                THRESHOLD.FIRST,
                '#f1f075',
                THRESHOLD.SECOND,
                '#f28cb1'
            ],
            circleRadius: [
                'step',
                ['get', 'point_count'],
                RADIUS.SMALL,
                THRESHOLD.FIRST,
                RADIUS.MEDIUM,
                THRESHOLD.SECOND,
                RADIUS.LARGE,
            ],
        };
    },
    methods: {
        setupMap(center) {
            this.map = new mapboxgl.Map({
                container: 'map',
                style: 'mapbox://styles/mapbox/streets-v12',
                center: center,
                zoom: this.zoom
            });

            this.map.on('load', () => {
                // Add a popup to the map
                this.popup = new mapboxgl.Popup({
                    className: 'n-map-popup'
                });
                this.loadPlaces()
                this.addMapEvents()
                this.addControls()
                this.addControlEvents()
                this.setMapInteractivity()
                this.map.resize()
            })
        },
        addControls() {
            this.mapboxSearchField = new MapboxGeocoder({
                accessToken: mapboxgl.accessToken,
                mapboxgl: mapboxgl,
                marker: false,
            })
            this.map.addControl(this.mapboxSearchField);
            this.map.addControl(new mapboxgl.NavigationControl());
            this.map.addControl(new mapboxgl.GeolocateControl({
                positionOptions: {
                    enableHighAccuracy: true
                },
                trackUserLocation: true,
                showUserHeading: true
            }));
        },
        addClusterLayers() {
            this.map.addLayer({
                id: 'clusters',
                type: 'circle',
                source: 'places',
                filter: ['has', 'point_count'],
                paint: {
                    'circle-color': this.circleColor,
                    'circle-radius': this.circleRadius
                }
            });

            this.map.addLayer({
                id: 'cluster-count',
                type: 'symbol',
                source: 'places',
                filter: ['has', 'point_count'],
                layout: this.clusterTextStyle
            });

            this.map.addLayer({
                id: this.placesData.layerID,
                type: 'symbol',
                source: 'places',
                filter: ['!', ['has', 'point_count']],
                layout: {
                    'icon-image': ['get', 'icon'],
                    'icon-size': 1,
                    'icon-allow-overlap': true,
                    'icon-ignore-placement': true
                },
            });

            this.map.addLayer({
                id: `selected-${this.placesData.layerID}`,
                type: 'symbol',
                source: 'places',
                filter: ['!', ['has', 'point_count']],
                layout: {
                    'icon-image': 'selected-icon',
                    'icon-size': 1,
                    'icon-allow-overlap': true,
                    'icon-ignore-placement': true
                },
                paint: {
                    'icon-opacity': [
                        'case',
                        ['boolean', ['feature-state', 'selected'], false],
                        1,
                        0
                    ]
                }
            });
        },
        loadPlaces() {
            this.loadImages().then(() => {
                this.map.addSource('places', {
                    type: 'geojson',
                    data: this.placesData.data,
                    cluster: true,
                    clusterMaxZoom: 14, // Max zoom to cluster points on
                    clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
                });
                this.addClusterLayers();
            })

        },
        loadImages() {
            const images = [
                {name: 'main-icon', url: '/static/img/map_images/marker_icon_active.png'},
                {name: 'selected-icon', url: '/static/img/map_images/marker_icon_connected_to_building.png'},
            ]
            const promises = images.map(image => {
                return new Promise((resolve, reject) => {
                    this.map.loadImage(image.url, (error, img) => {
                        if (error) {
                            reject(error);
                        } else {
                            this.map.addImage(image.name, img);
                            resolve();
                        }
                    });
                });
            });
            return Promise.all(promises);
        },
        addControlEvents() {
            this.mapboxSearchField.on('result', (e) => {
                this.$emit(EVENTS.ON_SEARCH, e.result.center)
            });
        },
        addMapEvents() {
            const PRESSED_ICON_INDEX = 0

            this.map.on('click', 'clusters', (e) => {
                const features = this.map.queryRenderedFeatures(e.point, {
                    layers: ['clusters']
                });
                const clusterId = features[PRESSED_ICON_INDEX].properties.cluster_id;
                this.map.getSource('places').getClusterExpansionZoom(
                    clusterId,
                    (err, zoom) => {
                        if (err) return;

                        this.map.easeTo({
                            center: features[PRESSED_ICON_INDEX].geometry.coordinates,
                            zoom: zoom
                        });
                    }
                );
            });

            this.map.on('click', this.placesData.layerID, (e) => {
                this.handleLocationClick(e)
            });

            this.map.on('mouseenter', 'clusters', () => {
                this.map.getCanvas().style.cursor = 'pointer';
            });
            this.map.on('mouseleave', 'clusters', () => {
                this.map.getCanvas().style.cursor = '';
            });


            this.map.on('mouseenter', this.placesData.layerID, (e) => {
                this.map.getCanvas().style.cursor = 'pointer';
                this.addPopup(e)
            });
            this.map.on('touchstart', this.placesData.layerID, (e) => {
                this.map.getCanvas().style.cursor = 'pointer';
                this.addPopup(e)
            });

            this.map.on('mouseleave', this.placesData.layerID, (e) => {
                this.map.getCanvas().style.cursor = '';
                this.popup.remove()
            });
            this.map.on('touchend', this.placesData.layerID, () => {
                this.map.getCanvas().style.cursor = '';
                this.popup.remove();
            });
        },
        calculateCoordinates(coordinates, lng) {
            // Ensure that if the map is zoomed out such that multiple
            // copies of the feature are visible, the popup appears
            // over the copy being pointed to.
            const PRESSED_ICON_INDEX = 0
            const DEGREES_ONE_EIGHTY = 180
            const DEGREES_THREE_SIXTY = 360
            const currentLng = coordinates[PRESSED_ICON_INDEX];
            if (Math.abs(lng - currentLng) > DEGREES_ONE_EIGHTY) {
                coordinates[PRESSED_ICON_INDEX] += lng > currentLng ? DEGREES_THREE_SIXTY : -DEGREES_THREE_SIXTY;
            }
        },
        handlePlaceHover(e) {
            this.addPopup(feature, coordinates)
            // Add handleRoute here when routing is ready
        },
        handleLocationClick(e) {
            const PRESSED_ICON_INDEX = 0
            const feature = e.features[PRESSED_ICON_INDEX];
            this.$emit(EVENTS.SELECT_LOCATION, feature?.id)
        },
        addPopup(e) {
            const PRESSED_ICON_INDEX = 0
            const feature = e.features[PRESSED_ICON_INDEX];
            const lng = e.lngLat.lng;

            const coordinates = feature.geometry.coordinates.slice();

            this.calculateCoordinates(coordinates, lng);

            const props = feature.properties;
            const popupContent = document.createElement('div');

            const tempApp = Vue.createApp({
                render() {
                    return Vue.h(NMapboxPopup, {...props});
                }
            });

            tempApp.component('n-mapbox-popup', NMapboxPopup);
            tempApp.config.compilerOptions.delimiters = ["[[[", "]]]"]
            tempApp.mount(popupContent);

            this.popup
                .setLngLat(coordinates)
                .setDOMContent(popupContent)
                .addTo(this.map);
        },
        handleRoute(coordinates) {
            const end = {
                type: 'FeatureCollection',
                features: [
                    {
                        type: 'Feature',
                        properties: {},
                        geometry: {
                            type: 'Point',
                            coordinates: coordinates
                        }
                    }
                ]
            };
            if (this.map.getLayer('end')) {
                this.map.getSource('end').setData(end);
                this.getRoute(coordinates);
                return
            }

            this.map.addLayer({
                id: 'end',
                type: 'circle',
                source: {
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features: [
                            {
                                type: 'Feature',
                                properties: {},
                                geometry: {
                                    type: 'Point',
                                    coordinates: coordinates
                                }
                            }
                        ]
                    }
                },
                paint: {
                    'circle-radius': 10,
                    'circle-color': '#f30'
                }
            });

            this.getRoute(coordinates);
        },
        generateRoute() {
            //TODO
            console.log(this.selectedMarkers)
        },
        async getRoute(end) {

            // make a directions request using cycling profile
            // an arbitrary start will always be the same
            const startLat = this.selectedMarkers[0].coordinates[0]
            const startLng = this.selectedMarkers[0].coordinates[1]
            const endLat = end[0]
            const endLng = end[1]
            // only the end or destination will change
            const query = await fetch(
                `https://api.mapbox.com/directions/v5/${ROUTING_PROFILES.DRIVING}/${startLat},${startLng};${endLat},${endLng}?steps=true&geometries=geojson&access_token=${mapboxgl.accessToken}`,
                {method: 'GET'}
            );
            const json = await query.json();
            const data = json.routes[0];
            const route = data.geometry.coordinates;
            const geojson = {
                type: 'Feature',
                properties: {},
                geometry: {
                    type: 'LineString',
                    coordinates: route
                }
            };
            // if the route already exists on the map, we'll reset it using setData
            if (this.map.getSource('route')) {
                this.map.getSource('route').setData(geojson);
                return
            }
            // otherwise, we'll make a new request
            this.map.addLayer({
                id: 'route',
                type: 'line',
                source: {
                    type: 'geojson',
                    data: geojson
                },
                layout: {
                    'line-join': 'round',
                    'line-cap': 'round'
                },
                paint: {
                    'line-color': '#3887be',
                    'line-width': 5,
                    'line-opacity': 0.75
                }
            });
        },
        getUserLocation() {
            return new Promise((resolve, reject) => {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(resolve, reject);
                } else {
                    reject(new Error('Geolocation is not supported by this browser.'));
                }
            });
        },
        setMapInteractivity() {
            const controls = document.getElementsByClassName('mapboxgl-ctrl')
            const mapEl = document.getElementById('map')

            if (!this.isDisabled) {
                mapEl.style.pointerEvents = 'unset'
                for (let control of controls) {
                    control.style.visibility = 'unset'
                }
                return
            }
            mapEl.style.pointerEvents = 'none'
            for (let control of controls) {
                control.style.visibility = 'hidden'
            }
        }
    },
    mounted() {


    },
    template: `
<!--        <div id="controls"></div>-->
<!--        <button @click="generateRoute">Generate route from selected places</button>-->
        <div id='map' style='width: 100%; height: auto; flex: 1; border-radius: 5px; border: 2px solid #55b8ff'></div>
    `,
};