import Inputs from "./inputs/index.js"
import NMap from "./n-map.js"
import TableIndex from "./table/index.js"
import Buttons from "./buttons/index.js"
import Calendar from "./calendar/index.js";
import Tabs from "./tabs/index.js"
import Planning from "./planning/index.js"
import Mapbox from "./mapbox/index.js"
import NFlex from "./styling/n-flex.js";
import NBlock from "./styling/n-block.js";
import NSimpleLayout from "./example-layout.js"
import NTemplate from "./n-template.js"
import Popups from "./popups/index.js";
import NToast from "./n-toast.js";
import NToastHandler from "./n-toast-handler.js";
import NNavbar from "./n-navbar.js";
import Wizard from "./wizard/index.js";
import SermiHeader from "./sermi-header.js";
import NTooltip from "./n-tooltip.js";
import NDialog from "./n-dialog.js";

import CaseStatusLabel from "./status_labels/case-status-label.js";
import CaseResultStatusLabel from "./status_labels/case-result-status-label.js";

import NDashboardTabs from "./n-dashboard-tabs.js";
import NPopper from "./n-popper.js";
import NPdfDisplay from "./n-pdf-display.js";
import NChecklist from "./n-checklist.js";
import NChecklistColumn from "./n-checklist-column.js";
import NChecklistItem from "./n-checklist-item.js";
import NChecklistDecisionContainer from "./n-checklist-decision-container.js";
import CaseTypeLabel from "./status_labels/case-type-label.js";
import NCopyToClipboard from "./n-copy-to-clipboard.js";
import NChecklistBreadcrumb from "./n-checklist-breadcrumb.js";
import ProcessStepCard from "./process-step-card.js";
import NAccordion from "./n-accordion.js";
import NPopupImage from "./n-popup-image.js";


export default {
    ...Inputs,
    ...TableIndex,
    ...Buttons,
    ...Calendar,
    ...Tabs,
    ...Popups,
    ...Planning,
    ...Wizard,
    ...Mapbox,
    // ...GMaps,

    "n-flex": NFlex,
    "n-block": NBlock,
    "n-map": NMap,
    "n-simple-layout": NSimpleLayout,
    "n-template": NTemplate,
    "n-toast": NToast,
    "n-toast-handler": NToastHandler,
    "n-navbar": NNavbar,
    "n-checklist": NChecklist,
    "n-checklist-column": NChecklistColumn,
    "n-checklist-item": NChecklistItem,
    "n-checklist-decision-container": NChecklistDecisionContainer,
    "sermi-header": SermiHeader,
    "n-tooltip": NTooltip,
    "n-dialog": NDialog,
    "case-status-label": CaseStatusLabel,
    "case-type-label": CaseTypeLabel,
    "case-result-status-label": CaseResultStatusLabel,
    "n-dashboard-tabs": NDashboardTabs,
    "n-popper": NPopper,
    "n-pdf-display": NPdfDisplay,
    "n-copy-to-clipboard": NCopyToClipboard,
    'n-checklist-breadcrumb': NChecklistBreadcrumb,
    'process-step-card': ProcessStepCard,
    'n-accordion': NAccordion,
    'n-popup-image': NPopupImage,
};
